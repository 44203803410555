import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = () => (
    <Layout>
        <SEO title="Services" />
        <div className="px-2 pt-12 pb-24 font-sans text-green-main">
            <h1 className="text-xl text-center tracking-widest">Services</h1>
            <p className="text-sm text-center pt-4">Note: All services are shown at a per-hour price.</p>
            <div className="flex flex-col md:w-3/4 md:mx-auto lg:w-full mt-4">
                <div className="flex flex-col lg:flex-row lg:mx-6 xl:mx-12">
                    <div className="lg:flex-1">
                        <div className="bg-white px-3 py-4 lg:p-6 shadow-xl rounded-lg mt-2 lg:mx-2 xl:mx-4">
                            <h2 className="text-lg tracking-widest">Pedigree Analysis</h2>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>3-Generation Pedigree</span><span>$25</span></h3>
                            <p className="text-sm pt-1 opacity-75">Analysis of three generations (8 individuals). Identify possible problems.</p>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>3-Generation Pedigree with Descendants</span><span>$40</span></h3>
                            <p className="text-sm pt-1 opacity-75">Analysis of three generations (8 individuals) with these individuals’ children as well. Identify possible problems.</p>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>4-Generation Pedigree</span><span>$30</span></h3>
                            <p className="text-sm pt-1 opacity-75">Analysis of four generations (16 individuals). Identify possible problems.</p>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>4-Generation Pedigree with Descendants</span><span>$45</span></h3>
                            <p className="text-sm pt-1 opacity-75">Analysis of four generations (16 individuals) with these individuals’ children as well. Identify possible problems.</p>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>5-Generation Pedigree</span><span>$35</span></h3>
                            <p className="text-sm pt-1 opacity-75">Analysis of five generations (32 individuals). Identify possible problems.</p>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>5-Generation Pedigree with Descendants</span><span>$50</span></h3>
                            <p className="text-sm pt-1 opacity-75">Analysis of five generations (32 individuals) with these individuals’ children as well. Identify possible problems.</p>
                        </div>
                    </div>
                    <div className="lg:flex-1">
                        <div className="bg-white px-3 py-4 lg:p-6 shadow-xl rounded-lg mt-4 lg:mt-2 lg:mx-2 xl:mx-4">
                            <h2 className="text-lg tracking-widest">Research</h2>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>General Research</span><span>$25</span></h3>
                            <p className="text-sm pt-1 opacity-75">This is the starter pack. If you would like to begin building your tree and learn about your family from the beginning, or just find information on an ancestor of whom little is known, this is for you.</p>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>Generational Research</span><span>$30</span></h3>
                            <p className="text-sm pt-1 opacity-75">This consists of research for one nuclear family (i.e. two parents and their children).</p>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>Lineal Research</span><span>$35</span></h3>
                            <p className="text-sm pt-1 opacity-75">If you have an interest in researching one family line and not so much another, I can do that too. Paternal, maternal, or a mix? I’ll trace the line you are interested in and find the maximum amount of information I’m able to on these individuals. Maybe you just want to know if you have Italian roots or if you are eligible to join a Lineage Society, such as the Daughters of the American Revolution. I can find that information for you.</p>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>Brick Wall Breaker</span><span>$45</span></h3>
                            <p className="text-sm pt-1 opacity-75">Unless you are royalty, most families come to a branch of their tree that is known in the genealogy world as a “brick wall.” This generally occurs when a paper trail ends on an ancestor or there are no more clues as to where to search next. That’s where I come in. I’ve learned how to break down these barriers to hopefully break the brick wall.</p>
                            <h3 className="flex justify-between text-md tracking-wide pt-3"><span>Biographical Sketch</span><span>$35</span></h3>
                            <p className="text-sm pt-1 opacity-75">You want to know more about a certain relative. I can research and provide you with a biographical sketch. With research, I might be able to uncover what he/she looked like, what he/she might have experienced during his/her childhood, or how natural or historical events might have changed his/her life.</p>
                        </div>
                    </div>
                </div>
                <div className="p-4 mt-8 lg:mx-48 xl:mx-64">
                    <h2 className="text-md tracking-widest text-center">Important Info to Know</h2>
                    <p className="text-sm pt-3">Fifty percent of the anticipated final charge is due before services are rendered. This is to pay for any documents that might be obtained on your behalf, as well as shipping charges if applicable.</p>
                    <p className="text-sm pt-3">Prices may vary according to time period and area of ancestral home, i.e. pre-1800 research will be more costly than 1801 and later.</p>
                    <p className="text-sm pt-3">Note: Some resources may not be available due to privacy laws, while others might have been destroyed by natural disaster or might not have been created at all. These cases will be noted within my research report, in which case I will seek supplemental records.</p>
                </div>
                <div className="p-4 mt-8 lg:mx-48 xl:mx-64">
                    <h2 className="text-md tracking-widest text-center">For Fellow Genealogical Researchers</h2>
                    <p className="text-sm pt-3">I will visit and research records at repositories and cemeteries throughout the continental United States; any flight cost I incur will be at no cost to you. Please contact me for pricing on this matter.</p>
                </div>
            </div>
        </div>
    </Layout>
)

export default ServicesPage